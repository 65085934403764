import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

//IMPORT TARGET TOKO
export const importTargetCategoryVolume = createAsyncThunk(
  'customer-target-category-volume/import',
  async ({ formdata }) => {
    const response = await axios
      .post(`${URL_API}/v1/api/target_category_volume/`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//TARGET CATEGORY ALL
export const getTargetCategoryVolumeAll = createAsyncThunk(
  'customers-target-category-volume/all',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      by: 'def.id',
      customer_code: parameter.customer_code,
      name: parameter.name,
      sort: 'desc',
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/target_category_volume/select?`, config)
    //console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

//TARGET CATEGORY DISTICT
export const getTargetCategoryVolumeAllDistict = createAsyncThunk(
  'customers-target-category-volume/all/distict',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      by: 'def.id',
      customer_code: parameter.customer_code,
      sort: 'desc',
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(
      `${URL_API}/v1/api/target_category_volume/select/distinct?`,
      config,
    )
    //console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

//TARGET CATEGORY BY ID
export const getTargetCategoryVolumeByID = createAsyncThunk(
  'customers-target-category-volume/id',
  async (params) => {
    console.log('API target katgori mysm hit')
    const response = await axios.get(
      `${URL_API}/v1/api/target_category_volume/id/` + params.customer_id,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    return response.data.data == null ? [] : response.data.data
  },
)

//REPORT TARGET CATEGORY ALL
export const getReportTargetCategoryVolumeAll = createAsyncThunk(
  'customers-target-category-volume/report',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      customer_code: parameter.customer_code,
      sort: 'desc',
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      names: parameter.names,
      start_date: parameter.start_date,
      end_date: parameter.end_date,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/target_category_volume/report?`, config)
    console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

const targetCategoryVolumeEntity = createEntityAdapter({
  selectId: (targetCategoryVolume) => targetCategoryVolume.id,
})

const targetCategoryVolumeSlice = createSlice({
  name: 'targetCategoryVolume',
  initialState: targetCategoryVolumeEntity.getInitialState(),
  extraReducers: {
    [importTargetCategoryVolume.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        targetCategoryVolumeEntity.addOne(state, action.payload)
      }
    },

    [getTargetCategoryVolumeAll.fulfilled]: (state, action) => {
      targetCategoryVolumeEntity.setAll(state, action.payload)
    },
    [getTargetCategoryVolumeAllDistict.fulfilled]: (state, action) => {
      targetCategoryVolumeEntity.setAll(state, action.payload)
    },
    [getTargetCategoryVolumeByID.fulfilled]: (state, action) => {
      targetCategoryVolumeEntity.setAll(state, action.payload)
    },
    [getReportTargetCategoryVolumeAll.fulfilled]: (state, action) => {
      targetCategoryVolumeEntity.setAll(state, action.payload)
    },
  },
})
export const targetCategoryVolumeSelector = targetCategoryVolumeEntity.getSelectors(
  (state) => state.targetCategoryVolume,
)
export const selectTargetCategoryVolume = (state) => state.targetCategoryVolume
export default targetCategoryVolumeSlice.reducer
