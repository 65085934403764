import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//TARGET MONTHLY
export const getTargetMonth = createAsyncThunk('customers-target/month', async (parameter) => {
  console.log('API target mysm hit')
  // const response = await axios.get(
  //   `${URL_API}/v1/api/customer/target/select?by=def.id&customer_id=` + params.customer_id,
  //   {
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.getItem('token'),
  //     },
  //   },
  // )
  // return response.data.data == null ? [] : response.data.data

  const params = {
    by: 'def.id',
    customer_id: parameter.customer_id,
    year: parameter.year,
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/customer/target/year?`, config)
  //console.log('resp data: ', response.data.data)
  return response.data.data
})

const targetMonthEntity = createEntityAdapter({
  selectId: (targetMonth) => targetMonth.id,
})

const targetMonthSlice = createSlice({
  name: 'targetMonth',
  initialState: targetMonthEntity.getInitialState(),
  extraReducers: {
    [getTargetMonth.fulfilled]: (state, action) => {
      targetMonthEntity.setAll(state, action.payload)
    },
  },
})

export const targetMonthSelector = targetMonthEntity.getSelectors((state) => state.targetMonth)
export const selectTargetMonth = (state) => state.targetMonth
export default targetMonthSlice.reducer
