import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//REPORT
export const getReportFolder = createAsyncThunk('folder/report', async (parameter) => {
  const params = {
    start_date: parameter.start_date,
    end_date: parameter.end_date,
    region_ids: parameter.region_ids,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios
    .get(`${URL_API}/v1/api/upload/report?`, config)

    .catch((err) => {
      // console.log(err.response)
    })

  return response.data.data == null ? [] : response.data.data
})

const folderReportEntity = createEntityAdapter({
  selectId: (folderReport) => folderReport.branch.id,
})

const folderReportSlice = createSlice({
  name: 'folderReport',
  initialState: folderReportEntity.getInitialState(),
  extraReducers: {
    [getReportFolder.fulfilled]: (state, action) => {
      folderReportEntity.setAll(state, action.payload)
    },
  },
})

export const folderReportSelector = folderReportEntity.getSelectors((state) => state.folderReport)
export default folderReportSlice.reducer
